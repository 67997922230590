import { STORAGE_CONST } from "../constants/constant";
import LocalStorageService from "./local-storage.service";

const AuthService = {
    isTokenExist,
    getToken,
    getUserInfo,
    getRole,
    isAdminTokenExist,
    isPublicTokenExist
};

function getRole() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        return userInfo?.user?.role;
    }
    return null;
}

function isTokenExist() {
    if (window.location.pathname.includes("/public")) {
        let token = LocalStorageService.get(STORAGE_CONST.PUBLIC_TOKEN);
        if (token) {
            return true;
        }
        return false;
    }
    let token = LocalStorageService.get(STORAGE_CONST.TOKEN);
    if (token) {
        return true;
    }
    return false;
}
function isAdminTokenExist() {
    let token = LocalStorageService.get(STORAGE_CONST.TOKEN);
    if (token) {
        return true;
    }
    return false;
}

function isPublicTokenExist() {
    let token = LocalStorageService.get(STORAGE_CONST.PUBLIC_TOKEN);
    if (token) {
        return true;
    }
    return false;
}
function getToken() {
    if (window.location.pathname.includes("/public")) {
        let token = LocalStorageService.get(STORAGE_CONST.PUBLIC_TOKEN);
        if (token) {
            return token;
        }
        return null;
    }
    let token = LocalStorageService.get(STORAGE_CONST.TOKEN);
    if (token) {
        return token;
    }
    return null;
}

function getUserInfo() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        return userInfo?.user;
    }
    return null;
}

export default AuthService;
